import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Hero from "../components/Hero";
import howImg from "../assets/img/general-banner-promedic.png";
import HowCard from "../components/HowCards";
import how1 from "../assets/img/how-section/1.svg";
import how2 from "../assets/img/how-section/2.svg";
import how3 from "../assets/img/how-section/3.svg";
import how4 from "../assets/img/how-section/4.svg";
import how5 from "../assets/img/how-section/5.svg";
import how6 from "../assets/img/how-section/6.svg";
import how7 from "../assets/img/how-section/7.svg";
import how8 from "../assets/img/how-section/8.svg";
import how9 from "../assets/img/how-section/9.svg";

export default function ComoHacemos() {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "ProMedic - ¿Cómo lo Hacemos?";
  }, []);

  return (
    <>
      <Hero img={howImg} />
      <p onClick={() => navigate(-1)} className="text-[#1f7b57] hover:cursor-pointer hover:text-gray-700 text-sm mt-5 mx-auto w-9/12 mb-5">Volver a Servicios</p>
      <h1 className="mx-auto w-9/12 text-4xl font-bold mb-8">
        ¿Cómo lo Hacemos?
      </h1>
      <div className="grid md:grid-cols-3 mx-auto mb-8 w-9/12 gap-4">
        <HowCard
          img={how1}
          description="Diagnóstico de tu empresa y/o negocio en salud ocupacional."
        />
        <HowCard
          img={how2}
          description="Presentación de plan integral de servicios que se requieran para cumplimiento y correcta aplicación de la normativa"
        />
        <HowCard
          img={how3}
          description="Desarrollo de entregable y plan de capacitación para operadores de Servicio Médico"
        />
        <HowCard
          img={how4}
          description="Reclutamiento y selección de elementos adecuados"
        />
        <HowCard
          img={how5}
          description="Inducción y capacitación a los profesionales de la salud entorno a las necesidades del cliente"
        />
        <HowCard
          img={how6}
          description="Fortalezas, oportunidades, debilidades, amenazas (FODA) de la situación actual"
        />
        <HowCard
          img={how7}
          description="Implementación de procesos operativos y mejores prácticas"
        />
        <HowCard
          img={how8}
          description="Revisión mensual de SLA (Service Level Agreement)"
        />
        <HowCard
          img={how9}
          description="Score de salud anual con base a estudios realizados, hallazgos y recomendaciones por paciente"
        />
      </div>
    </>
  );
}
