import post1 from "../assets/img/blog/ocupacional_promedic.png"
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet';

export default function CulturaOcupacional() {
    return(
        <>
        <Helmet>
        <title>ProMedic Blog - Cultura en Salud Ocupacional</title>
        <meta name="description" content="La salud ocupacional se encarga de promover y mantener el más alto grado de bienestar físico, mental y social de todos los trabajadores." />
        </Helmet> 
        <div className="wrapper w-11/12 md:w-10/12 mx-auto my-5 md:my-16 md:flex md:justify-between">
            <div class="main w-full md:w-[60%]">
                <img src={post1} alt="" />
                <h1 className="title text-3xl font-bold my-3">Cultura en Salud Ocupacional</h1>
                <p className="extract text-lg text-gray-700">La salud ocupacional se encarga de promover y mantener el más alto grado de bienestar físico, mental y social de todos los trabajadores.</p>
                <p className="body-post text-gray-700">
                    ¿Sabías que, cerca del 65% de los accidentes en el trabajo, no son atendidos como deberían?
                    <br />
                    <br />
                    Un gran porcentaje de estos no cuentan, o no cubren, con el servicio médico correspondiente que proteja a los colaboradores. 
                    <br />
                    El día de hoy, desde ProMedic, platicamos sobre el concepto de “salud ocupacional”.<br /><br />
                    De acuerdo con datos del IMSS, y sin considerar los datos y efectos de los años de la pandemia por las alteraciones que ésta implicó en el mercado laboral, en la última década se ha registrado un promedio de 410,000 accidentes de trabajo por año, es decir 2.2 eventos por cada 100 trabajadores.<br /><br />
                    Problemas generados por la falta de prevención en salud ocupacional.<br />
                    No sólo se habla de riesgos físicos, la salud ocupacional también engloba malestares o padecimientos relacionados con el trabajo diario, como:
                    <ul className="list-disc pl-10">
                        <li>Trastornos por movimientos repetitivos.</li>
                        <li>Problemas del oído causados por la exposición al ruido.</li>
                        <li>Problemas que afectan la vista.</li>
                        <li>Inconvenientes causados por inhalar, tocar o ingerir sustancias peligrosas.</li>
                        <li>Exposición a gérmenes o bacterias en centros de salud.</li>
                        <li>Agotamiento laboral.</li>
                        <li>Trastornos en la espalda por malas posturas o manejo manual de cargas.</li>
                        <li>Estrés, enfermedad mental o cualquier otro tipo de problema emocional, entre otros.</li>
                    </ul> 
                    <br />
                    El estrés laboral se ha convertido en un gran indicador. Según datos de la Organización Mundial de Salud (OMS), México se lleva el primer lugar este trastorno mental producido por el ámbito laboral, y del cual el rango promedio de edad es entre 25 a 35 años que representan el 35% del total.
                    ¿Por qué es tan importante tener una Cultura en Salud Ocupacional?<br /><br />
                    La OMS lo define como: “La salud ocupacional es un área de trabajo en salud pública para promover y mantener el más alto grado de bienestar físico, mental y social de los trabajadores en todas las ocupaciones”. 
                    <br />
                    <br />
                    Sus objetivos están muy definidos:
                    <ol className="list-decimal pl-10">
                        <li>Mantenimiento y promoción de la salud y capacidad de trabajo de los trabajadores.</li>
                        <li>Mejorar condiciones de trabajo y el entorno laboral para que sean propicios para la seguridad y la salud.</li>
                        <li>Desarrollar una organización del trabajo y culturas laborales que deberían reflejar los sistemas de valores esenciales, adoptados por la empresa en cuestión, e incluir sistemas de gestiones eficaces, políticas de personal, principios de participación y prácticas voluntarias en gestiones relacionadas con la calidad para mejorar la seguridad y la salud en el trabajo.</li>
                    </ol>	
                    <br />
                    En PROMEDIC entendemos la importancia del bienestar de los trabajadores en las industrias, por ello nuestro principal objetivo es generar planes integrales en salud ocupacional que mejoren la productividad de las organizaciones y cumplan con el requerimiento normativo.
                </p>
            </div>
            <div className="sidebar w-full md:w-[30%] mb-16 h-[25vh] mx-auto">
                <button className="text-right my-5 w-full text-green-900 text-xl border-red-600"><Link to="/">← Regresar a Inicio</Link></button>
                <h2 className="text-right text-xl font-bold">Artículos</h2>
                <ul className="my-3 text-green-800 text-lg text-right leading-8">
                    <li>
                        <Link to="/empresas/cultura-salud-ocupacional" className="hover:font-bold ease-out duration-120">Cultura en Salud Ocupacional</Link>
                    </li>
                    <li>
                        <Link to="/empresas/nom-30" className="hover:font-bold ease-out duration-120">¿A qué se refiere la NOM-030?</Link>
                    </li>
                    <li>
                        <Link to="/empresas/factores-riesgo-psicosocial" className="hover:font-bold ease-out duration-120">¿Qué son los factores de riesgo psicosocial?</Link>
                    </li>
                    <li>
                        <Link to="/empresas/principales-accidentes-en-trabajo" className="hover:font-bold ease-out duration-120">¿Cuáles son los principales accidentes en el trabajo?</Link>
                    </li>
                </ul>
            </div>
        </div>
        </>
    )
}