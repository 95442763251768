import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";

export default function ContactForm() {
  const [captchaValid, setCaptchaValid] = useState(null);

  const form = useRef();
  const navigate = useNavigate();

  const sendEmail = (e) => {

    if (captcha.current.getValue()) {
        setCaptchaValid(true);
        navigate('/thank-you');
    } else {
      setCaptchaValid(false);
      e.preventDefault();
    }

    emailjs
      .sendForm(
        "service_6wt5e85",
        "template_s24y1v8",
        form.current,
        "khoW7wKziiJkyWouZ"
      );
  };

  const captcha = useRef(null);

  const onChange = () => {
    if (captcha.current.getValue()) {
        setCaptchaValid(true);
    }
  };

  return (
    <>
      <form ref={form} onSubmit={sendEmail} className="grid md:p-5">
        <div className="mb-1 sm:mb-2">
          <label
            htmlFor="name"
            className="inline-block text-white mb-1 font-medium"
          >
            Nombre Completo
          </label>
          <input
            placeholder="Nombre Completo"
            required
            type="text"
            className="flex-grow w-full h-9 px-4 mb-2 transition duration-200 bg-slate-100 border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
            id="name"
            name="name"
          />
        </div>
        <div className="form-group grid grid-cols-2 gap-5">
          <div className="mb-1 sm:mb-2">
            <label
              htmlFor="email"
              className="inline-block text-white mb-1 font-medium"
            >
              E-mail
            </label>
            <input
              placeholder="mail@promedic.com.mx"
              required
              type="email"
              className="flex-grow w-full h-9 px-4 mb-2 transition duration-200 bg-slate-100 border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
              id="email"
              name="email"
            />
          </div>
          <div className="mb-1 sm:mb-2">
            <label
              htmlFor="phone"
              className="inline-block text-white mb-1 font-medium"
            >
              Teléfono
            </label>
            <input
              placeholder="55 5555 5555"
              required
              type="tel"
              className="flex-grow w-full h-9 px-4 mb-2 transition duration-200 bg-slate-100 border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
              id="phone"
              name="phone"
            />
          </div>
        </div>
        <div className="form-group grid grid-cols-2 gap-5">
          <div className="mb-1 sm:mb-2">
            <label
              htmlFor="job"
              className="inline-block text-white mb-1 font-medium"
            >
              Cargo
            </label>
            <input
              placeholder="Cargo/Puesto"
              required
              type="text"
              className="flex-grow w-full h-9 px-4 mb-2 transition duration-200 bg-slate-100 border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
              id="job"
              name="job"
            />
          </div>
          <div className="mb-1 sm:mb-2">
            <label
              htmlFor="company"
              className="inline-block text-white mb-1 font-medium"
            >
              Empresa
            </label>
            <input
              placeholder="Empresa"
              required
              type="text"
              className="flex-grow w-full h-9 px-4 mb-2 transition duration-200 bg-slate-100 border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
              id="company"
              name="company"
            />
          </div>
        </div>
        <div className="mb-1 sm:mb-2">
          <label
            htmlFor="message"
            className="inline-block text-white mb-1 font-medium"
          >
            Mensaje
          </label>
          <input
            placeholder="Deja tu mensaje"
            required
            type="textarea"
            className="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-slate-100 border-gray-300 rounded shadow-sm appearance-none focus:border-deep-purple-accent-400 focus:outline-none focus:shadow-outline"
            id="message"
            name="message"
          />
        </div>
        <div className="mt-4 mb-2 sm:mb-4">
          <button
            type="submit"
            className="inline-flex items-center justify-center w-full h-10 px-6 font-medium tracking-wide bg-green-700 text-white transition duration-200 rounded-lg shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
            ref={form}
            onSubmit={sendEmail}
          >
            Enviar
          </button>
          <div className="recaptcha mt-5">
            <ReCAPTCHA
              ref={captcha}
              sitekey="6LeY-8ciAAAAAIUiC6vxupVtSIWWY2_ps07bKEFd"
              onChange={onChange}
            />
          </div>
          { captchaValid === false && <div className="error-captcha text-red-700">Verifica el captcha</div>}
        </div>
        <div className="form-check">
          <input
            className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-slate-100 checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
            type="checkbox"
            value=""
            id="flexCheckChecked"
            required
          ></input>
          <label
            className="form-check-label inline-block text-xs md:text-base"
            htmlFor="flexCheckChecked"
          >
            *Acepto los términos y condiciones
          </label>
        </div>
      </form>
    </>
  );
}
