import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Hero from "../components/Hero";
import offerImg from "../assets/img/general-banner-promedic.png";

export default function NuestraOferta() {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "ProMedic - Nuestra Oferta";
  }, []);

  return (
    <>
      <Hero img={offerImg} position="relative" />
      <p
        onClick={() => navigate(-1)}
        className="text-[#1f7b57] hover:cursor-pointer hover:text-gray-700 text-sm mt-5 mx-auto w-9/12 mb-5"
      >
        Volver a Servicios
      </p>
      <h1 className="mx-auto w-9/12 text-4xl font-bold mb-8">
        ¿Por qué confiar en nosotros?
      </h1>
      <ul className="mx-auto w-9/12 leading-loose">
        <li>• Más de <b>18 años de experiencia.</b></li>
        <li>
          • Oferta de <b>servicios escalables acorde a la necesidad de cada empresa.</b>
        </li>
        <li>
          • Cumplimiento normativo como prestador de <b>servicios especializados
          (REPSE).</b>
        </li>
        <li>
          • Otorgamos un <b>score de salud</b> con base a criterios o gradientes de salud
          y estudios realizados con recomendaciones para <b>programas de
          seguimiento posterior por la empresa.</b>
        </li>
        <li>
          • <b>Universidad ProMedic® para el entrenamiento y capacitación de
          nuestros profesionales de la salud</b> con una matriz orientada a los
          temas: <b>Área clínica-Calidad-Salud Ocupacional-Normativa Aplicable-
          Soft y Hard Skills.</b>
        </li>
        <li>
          • Plantilla de más de 800 profesionales de la salud capacitados en salud
          ocupacional para la <b>realización e interpretación de estudios y
          atención a pacientes.</b>
        </li>
      </ul>
    </>
  );
}
