import post1 from "../assets/img/blog/accidentes_promedic.png"
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet';

export default function AccidentesTrabajo() {
    return(
        <>
        <Helmet>
        <title>ProMedic Blog - ¿Cuáles son los principales accidentes en el trabajo?</title>
        <meta name="description" content="La salud ocupacional se encarga de promover y mantener el más alto grado de bienestar físico, mental y social de todos los trabajadores." />
        </Helmet> 
        <div className="wrapper w-11/12 md:w-10/12 mx-auto my-5 md:my-16 md:flex md:justify-between">
            <div class="main w-full md:w-[60%]">
                <img src={post1} alt="" />
                <h1 className="title text-3xl font-bold my-3">¿Cuáles son los principales accidentes en el trabajo?</h1>
                <p className="extract text-lg text-gray-700">En México se registra un promedio de 1,100 accidentes en el trabajo al día. La mayoría de estos eventos suceden debido a los distintos riesgos a los cuales se enfrentan los trabajadores.</p>
                <p className="body-post mt-10 text-gray-700">
                La OIT (Organización Internacional del Trabajo) expresó que: “En México se registra un promedio de 1,100 accidentes en el trabajo al día.” La mayoría de estos eventos suceden debido a los distintos riesgos a los cuales se enfrentan los trabajadores, ya sea por el mal uso de maquinarias, instalaciones y/o herramientas, (actos y condiciones inseguros), por lo que es de suma importancia dar seguimiento a las normas oficiales mexicanas (NOM´s) que dan certeza y protección física a los colaboradores en su centro de trabajo.
                <br />
                <li className="list-disc pl-10">Caídas</li> 
                <br />
                Estos se dan principalmente por las anomalías en el área laboral en el que se encuentran los trabajadores, ya sea irregularidades en el suelo, pisos resbaladizos, desniveles que no estén visiblemente señalados o por la ausencia de señalamientos. Las caídas por trabajos en altura representan la primera causa de muerte en el trabajo en México.
                <br />
                <li className="list-disc pl-10">Heridas, laceraciones o amputaciones</li> 
                <br />
                Principalmente, se debe por el empleo incorrecto del equipo de seguridad, falta de conocimiento del manejo de los equipos por parte de los trabajadores, y la falta de mantenimiento de estos. Es indispensable para los trabajadores contar con un equipo de protección adecuado de acuerdo con los riesgos que puedan presentarse durante su ocupación.
                <br /><br />
                De acuerdo con datos del IMSS, y sin considerar los resultados para los años de la pandemia por las alteraciones que esta implicó en el mercado laboral, en la última década se ha registrado un promedio de 410,000 accidentes de trabajo por año, es decir, 2.2 eventos por cada 100 trabajadores.
                <br />
                <br />
                ¿Cómo evitar los accidentes del trabajo?
                <br />
                <br />
                La NOM 030 STPS 2009 establece que se requiere de un diagnóstico integral de seguridad y salud en el trabajo, que identifique y analice las condiciones y riesgos del área laboral.
                <br /><br />
                En ProMedic nos dedicamos a brindar servicios integrales de salud ocupacional mediante la adaptación de tecnologías con base en el cumplimiento normativo.
                <br /><br />
                Apoyamos a las empresas a identificar y evaluar las condiciones laborales que afectan la salud de tus trabajadores y la productividad de tu organización.
                <br />
                <br />
                Referencias: <br />
                Secretaría del Trabajo y Previsión Social – STPS. (s.f.) Riesgos de trabajo registrados en el IMSS. Consultado el 15 de septiembre de 2022 en <a href="http://www.imss.gob.mx/conoce-al-imss/memoria-estadistica-2021 México">http://www.imss.gob.mx/conoce-al-imss/memoria-estadistica-2021 México</a>
                <br />
                Secretaría del Trabajo y Previsión Social – STPS. (s.f.) Prevención de Accidentes en el Trabajo. Consultado el 15 de septiembre de 2022 en <a href="http://www.imss.gob.mx/salud-en-linea/prevencion-accidentestrabajo">http://www.imss.gob.mx/salud-en-linea/prevencion-accidentestrabajo</a>
                </p>
            </div>
            <div className="sidebar w-full md:w-[30%] mb-16 h-[25vh] mx-auto">
                <button className="text-right my-5 w-full text-green-900 text-xl border-red-600"><Link to="/">← Regresar a Inicio</Link></button>
                <h2 className="text-right text-xl font-bold">Artículos</h2>
                <ul className="my-3 text-green-800 text-lg text-right leading-8">
                    <li>
                        <Link to="/empresas/cultura-salud-ocupacional" className="hover:font-bold ease-out duration-120">Cultura en Salud Ocupacional</Link>
                    </li>
                    <li>
                        <Link to="/empresas/nom-30" className="hover:font-bold ease-out duration-120">¿A qué se refiere la NOM-030?</Link>
                    </li>
                    <li>
                        <Link to="/empresas/factores-riesgo-psicosocial" className="hover:font-bold ease-out duration-120">¿Qué son los factores de riesgo psicosocial?</Link>
                    </li>
                    <li>
                        <Link to="/empresas/principales-accidentes-en-trabajo" className="hover:font-bold ease-out duration-120">¿Cuáles son los principales accidentes en el trabajo?</Link>
                    </li>
                </ul>
            </div>
        </div>
        </>
    )
}