import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Hero from "../components/Hero"
import clientsImg from "../assets/img/general-banner-promedic.png"
import entornoImg from "../assets/img/entorno.png"

export default function Clientes() {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "ProMedic - Clientes y Entorno ProMedic";
    }, []);

    return(
        <>
            <Hero img={clientsImg} position="relative" />
            <p onClick={() => navigate(-1)} className="text-[#1f7b57] hover:cursor-pointer hover:text-gray-700 text-sm mt-5 mx-auto w-9/12 mb-5">Volver a Servicios</p>
            <h1 className="mx-auto w-9/12 text-4xl font-bold mb-8">
                Entorno ProMedic
            </h1>
            <img className="mx-auto w-8/12 my-3" src={entornoImg} alt="Entorno ProMedic" />
            <div className="bg-[#ecf5ee] p-10">
                <h2 className="mx-auto w-9/12 text-4xl font-bold my-5">
                    Algunos de nuestros clientes
                </h2>
                <ul className="grid grid-cols-2 p-3 md:grid-cols-7 mx-auto w-9/12">
                    <li className="text-sm text-center">Johnson Controls</li>
                    <li className="text-2xl text-[#1f7b57] font-bold text-center">LEGO</li>
                    <li className="text-lg text-gray-600 font-bold">KIA</li>
                    <li>LENOVO</li>
                    <li className="text-2xl text-[#1f7b57] font-bold text-center">Whirpool</li>
                    <li className="text-center">Navistar</li>
                    <li className="text-lg text-gray-600 font-bold">Siemens</li>
                </ul>
                <ul className="grid grid-cols-2 p-3 md:grid-cols-5 mx-auto w-8/12">
                    <li className="text-sm text-center">Schneider Electric</li>
                    <li className="text-2xl text-[#1f7b57] font-bold text-center">DHL</li>
                    <li className="text-sm text-center">Grupo PRODENSA</li>
                    <li className="text-2xl text-[#1f7b57] font-bold text-center">OXXO</li>
                    <li className="text-sm text-center">Nemak</li>
                </ul>
                <ul className="grid grid-cols-2 p-3 md:grid-cols-4 mx-auto w-9/12">
                    <li className="text-lg text-gray-600 font-bold">Soriana</li>
                    <li className="text-2xl text-[#1f7b57] font-bold text-center">Tecnológico de Monterrey</li>
                    <li className="text-lg text-gray-600 font-bold">HYUNDAI</li>
                    <li className="text-sm text-center">Ternium</li>
                </ul>
            </div>
        </>
    )
}